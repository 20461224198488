.banner {
  background-color: #d8e8d6;
  color: #444746;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  gap: 32px;
  height: 96px;
  width: 100%;
  margin: 0px auto;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  font-size: 0.7rem;
}
.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
}

.image {
  width: fit-content;
  height: fit-content;
  transform: translateY(-1px);
}
.logo {
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
}
.link {
  background-color: var(--clr-gray1);
  color: var(--clr-black);
  text-decoration: none;
  padding: 8px 24px;
  border-radius: 8px;
  font-weight: 600;
}

.title {
  font-size: 1rem;
  color: black;
  font-weight: 600;
  width: fit;
}
@media screen and (min-width: 768px) {
  .banner {
    display: none;
  }
}
