@import url("../../../public/fonts.css");

/* For header icon */
.language-header-icon {
  background-image: url("../../../public/icons/header-icon.svg");
}

/* Language icon in settings */
.language-icon {
  mask-image: url("../../../public/icons/language-switch.svg") !important;
}

/* language toggle icon for mobile */
.mobile-A-icon {
  mask-image: url("../../../public/icons/A.svg");
  background-color: var(--editor-unselected-color);
}

.backToTop {
  position: fixed;
  width: fit-content;
  bottom: 120px;
  right: 40px;
  user-select: none;
  background: white;
  padding: 1rem 1.2rem;
  font-size: 16px;
  color: #43753d;
  display: none;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 30px;
  border: #c3c3c3 solid 1px;
  z-index: 1000;
  transform: scale(0) translateY(100%);
  opacity: 0;
  transition: all 0.3s ease-in-out;
  animation: scaleIn 0.3s ease-in-out;
  cursor: pointer;
}

.backToTop svg {
  width: 24px;
  height: 24px;
  fill: #43753d;
  transform: translateY(-10%);
}

.backToTop.visible {
  opacity: 1;
  transform: scale(1);
  display: flex;
}

@keyframes scaleIn {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
.Editor-root {
  height: 100dvh;
  width: 100dvw;
}
@media screen and (max-width: 768px) and (pointer: coarse) {
  .Editor-root {
    height: calc(100vh - 96px);
    width: 100dvw;
  }
}
.keyboard-open > .Editor-root {
  height: 100dvh;
}
